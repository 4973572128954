import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import { UserProvider } from './UserContext';
import { Button } from './components/ui/button';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import AuthCallback from './pages/AuthCallback';

import Solo from './pages/Solo';
import Team from './pages/Team';
import Bank from './pages/Bank';
import User from './pages/User';
import Telegram from './pages/Telegram';
import Gameplay from './pages/Gameplay';
const App = () => {

  useEffect(() => {
    const shouldReload = Math.random() < 0.3; // 30% chance to reload
    const lastReloadTime = localStorage.getItem('lastReloadTime');
    const currentTime = new Date().getTime();
    const oneHour = 60 * 60 * 1000; // milliseconds in an hour

    if (shouldReload && (!lastReloadTime || currentTime - lastReloadTime > oneHour)) {
      localStorage.setItem('lastReloadTime', currentTime);
      window.location.reload();
    }
  }, []);

  const authenticateUser = () => {
    const token = localStorage.getItem('token');
    if(token) { return true; } else { return false; }
  };

  const PrivateRoute = ({ children }) => {
    return authenticateUser() ? (
      <>        
          {children}        
        {/* <nav className="fixed bottom-0 left-0 right-0 bg-background border-t z-10">
          <div className="container mx-auto px-6 py-4">
            <ul className="flex justify-between items-center">
              <li><Button variant="ghost" asChild size="lg"><Link to="/dashboard"><HomeIcon size={28} /></Link></Button></li>
            </ul>
          </div>
        </nav> */}
      </>
    ) : <Navigate to="/login" />;
  };
  return (
      <UserProvider>
      <Router>
        <div className="min-h-screen bg-background text-foreground">
          <Routes>
            <Route path="/" element={authenticateUser() ? <Navigate to="/dashboard" /> : <Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/auth" element={<AuthCallback />} />
            <Route path="/register" element={<Register />} />
            <Route path="/tma" element={<Telegram />} />
            <Route path="/gameplay" element={<Gameplay />} />
            {/* Wrap authenticated routes with PrivateRoute */}
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/solo" element={<PrivateRoute><Solo /></PrivateRoute>} />
            <Route path="/team" element={<PrivateRoute><Team /></PrivateRoute>} />
            <Route path="/bank" element={<PrivateRoute><Bank /></PrivateRoute>} />
            <Route path="/user" element={<PrivateRoute><User /></PrivateRoute>} />
          </Routes>
        </div>
      </Router>
      </UserProvider>
  );
};

export default App;
