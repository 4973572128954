import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";
import Phaser from 'phaser';

const Dashboard = () => {
  const { token, logout } = useUser();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const gameRef = useRef(null);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      fetchDashboardData();
    }

    return () => {
      if (gameRef.current) {
        gameRef.current.destroy(true);
      }
    };
  }, [token, navigate]);

  const fetchDashboardData = async () => {
    try {
      const response = await axios.get(
        "https://stacker-api.mydreamstudio.workers.dev/me/dashboard",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      setData(response.data);
    } catch (err) {
      setError("Failed to fetch dashboard data");
      console.error(err);
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  if (!data) {
    return <div className="">Loading...</div>;
  }

  return (
    <div className="min-h-screen p-2">
      <button onClick={handleLogout}>Logout</button>
      
      <Link to="/solo">Solo</Link>
      <Link to="/team">Team</Link>
      <Link to="/bank">Bank</Link>
      <Link to="/user">User</Link>

    </div>
  );
};

export default Dashboard;