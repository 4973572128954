import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";
import Phaser from 'phaser';

const User = () => {
  const { token, logout } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } 

  }, [token, navigate]);



  return (
    <div className="min-h-screen p-2">

        -- User --

      
      <Link to="/dashboard">Dashboard</Link>
      <Link to="/solo">solo</Link>
      <Link to="/team">team</Link>
      <Link to="/bank">bank</Link>

    </div>
  );
};

export default User;