import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";

const Team = () => {
  const { token } = useUser();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState("");
  const websocketRef = useRef(null);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      connectWebSocket("123"); // Replace "123" with the actual gameId
    }

    return () => {
      if (websocketRef.current) {
        websocketRef.current.close();
      }
    };
  }, [token, navigate]);

  const connectWebSocket = async (gameId) => {
    try {
      const response = await axios.get(`https://stacker-api.prototype.com.my/games/${gameId}/websocket-url`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      });
      
      const { websocketUrl } = response.data;
      const ws = new WebSocket(websocketUrl);

      ws.onopen = () => {
        console.log("WebSocket connected");
      };

      ws.onmessage = (event) => {
        const message = JSON.parse(event.data);
        setMessages(prevMessages => [...prevMessages, message]);
      };

      ws.onclose = () => {
        console.log("WebSocket disconnected");
      };

      websocketRef.current = ws;
    } catch (error) {
      console.error("Error connecting to WebSocket:", error);
    }
  };

  const sendMessage = () => {
    if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
      const message = {
        type: "chat",
        content: inputMessage
      };
      websocketRef.current.send(JSON.stringify(message));
      setInputMessage("");
    }
  };

  return (
    <div className="min-h-screen p-2">
      <h1>Team</h1>
      
      <div className="messages">
        {messages.map((msg, index) => (
          <div key={index}>{msg.content}</div>
        ))}
      </div>

      <input
        type="text"
        value={inputMessage}
        onChange={(e) => setInputMessage(e.target.value)}
        placeholder="Type a message"
      />
      <button onClick={sendMessage}>Send</button>

      <nav>
        <Link to="/dashboard">Dashboard</Link>
        <Link to="/solo">Solo</Link>
        <Link to="/bank">Bank</Link>
        <Link to="/user">User</Link>
      </nav>
    </div>
  );
};

export default Team;