import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';
import { startRegistration } from '@simplewebauthn/browser';

const Register = () => {
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const { token, login } = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate('/dashboard');
        }
    }, [token, navigate]);

    const handleRegister = async (e) => {
        e.preventDefault();
        setError('');

        if (!username.trim()) {
            setError('Username is required');
            return;
        }

        try {
            const optionsResponse = await axios.post('https://stacker-api.mydreamstudio.workers.dev/auth/register/start', { username });
            const options = optionsResponse.data;

            const registrationResponse = await startRegistration(options);

            const verificationResponse = await axios.post('https://stacker-api.mydreamstudio.workers.dev/auth/register/complete', {
                username: options.user.name,
                credential: registrationResponse,
            });

            const { token } = verificationResponse.data;
            if (token) {
                login(token);            
                navigate('/dashboard');
            }        
        } catch (error) {
            console.error(`Registration failed: ${error}`);
            setError(error.response?.data?.error || 'Registration failed');
        }
    };

    return (
        <div className="container mx-auto px-4 py-8 flex flex-col items-center">
            <h1 className="text-4xl font-bold mb-8">Register</h1>
            <form onSubmit={handleRegister} className="w-full max-w-sm">
                {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}
                <div className="mb-4">
                    <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2">
                        Username
                    </label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Enter your username"
                        required
                    />
                </div>
                <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
                >
                    Register with Passkey
                </button>
            </form>
        </div>
    );
};

export default Register;