import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";

const Bank = () => {
  const { token } = useUser();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const socketRef = useRef(null);

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      const initializeWebSocket = () => {
        // Use WSS (WebSocket Secure) protocol
        const wsUrl = `wss://stacker-api.prototype.com.my/socket`;

        // Initialize WebSocket connection
        socketRef.current = new WebSocket(wsUrl);

        socketRef.current.onopen = () => {
          console.log('WebSocket connection established');
          // Authenticate the WebSocket connection
          //socketRef.current.send(JSON.stringify({ type: 'auth', token }));
          socketRef.current.send(JSON.stringify({ message: '321', token }));
        };

        socketRef.current.onmessage = (event) => {
          const data = JSON.parse(event.data);
          console.log(event)
          //setMessages(prevMessages => [...prevMessages, data]);
        };

        socketRef.current.onerror = (error) => {
          console.error('WebSocket error:', error);
        };

        socketRef.current.onclose = () => {
          console.log('WebSocket connection closed');
        };
      };

      initializeWebSocket();
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [token, navigate]);

  const sendMessage = () => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ type: 'message', content: 'Hello from Bank!' }));
    }
  };

  return (
    <div className="min-h-screen p-2">
      <h1>-- Bank --</h1>
      
      <button onClick={sendMessage}>Send WebSocket Message</button>

      <div>
        <h2>Received Messages:</h2>
        <ul>
          {messages.map((msg, index) => (
            <li key={index}>{msg}</li>
          ))}
        </ul>
      </div>

      <nav>
        <Link to="/dashboard">Dashboard</Link>
        <Link to="/solo">Solo</Link>
        <Link to="/team">Team</Link>
        <Link to="/user">User</Link>
      </nav>
    </div>
  );
};

export default Bank;