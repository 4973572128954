import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useUser } from "../UserContext";
import Phaser from 'phaser';

const Solo = () => {
  const { token, logout } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } 

  }, [token, navigate]);



  return (
    <div className="min-h-screen p-2">

        -- solo --

      
      <Link to="/dashboard">Dashboard</Link>
      <Link to="/team">Team</Link>
      <Link to="/bank">Bank</Link>
      <Link to="/user">User</Link>

    </div>
  );
};

export default Solo;