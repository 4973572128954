import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { useUser } from '../UserContext';
import { useNavigate } from 'react-router-dom';
import { startAuthentication } from '@simplewebauthn/browser';

const Login = () => {
    const { token, login } = useUser();
    const [error, setError] = useState('');
    const [username, setUsername] = useState('');    
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
          navigate("/dashboard");
        } 
      }, [token, navigate]);    

    const handleLogin = async (e) => {
        if (e) e.preventDefault();
        setError('');

        try {
            const optionsResponse = await axios.post('https://stacker-api.mydreamstudio.workers.dev/auth/login/start', { username });
            console.log("Authentication options:", optionsResponse.data);

            if (!optionsResponse.data || typeof optionsResponse.data !== 'object') {
                throw new Error('Invalid response from server');
            }

            const authenticationOptions = {
                ...optionsResponse.data,
                allowCredentials: optionsResponse.data.allowCredentials,
                mediation: 'conditional'
            };

            console.log("Authentication options:", authenticationOptions);

            const authenticationResponse = await startAuthentication(authenticationOptions);
            console.log("Authentication response:", authenticationResponse);

            const verificationResponse = await axios.post('https://stacker-api.mydreamstudio.workers.dev/auth/login/complete', {
                credential: authenticationResponse,
                challengeId: optionsResponse.data.challengeId
            });
            console.log("Verification response:", verificationResponse.data);

            const token = verificationResponse.data;
            if (token) {
                login(token);
                navigate('/dashboard');
            } else {
                throw new Error('No token received from server');
            }
        } catch (error) {
            console.error('Login error:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
                console.error('Error status:', error.response.status);
                console.error('Error headers:', error.response.headers);
            } else if (error.request) {
                console.error('Error request:', error.request);
            } else {
                console.error('Error message:', error.message);
            }
            setError(error.response?.data?.error || error.message || 'Login failed. Please try again.');
        }
    };

    return (
        <div className="container mx-auto px-4 py-8 flex flex-col items-center">
            <h1 className="text-4xl font-bold mb-8">Login</h1>
            {error && <p className="text-red-500 text-xs italic mb-4">{error}</p>}
            <form onSubmit={handleLogin} className="w-full max-w-sm">
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                        Username
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        id="username"
                        type="text"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div className="flex items-center justify-between">
                    <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="submit"
                    >
                        Login with Passkey
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Login;