import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useUser } from "../UserContext";
import Phaser from 'phaser';

const Gameplay = () => {
  const { token, logout } = useUser();
  const gameRef = useRef(null);
  const [gameInstance, setGameInstance] = useState(null);

  useEffect(() => {
    // Function to get the viewport size
    const getViewportSize = () => {
      const tgApp = window.Telegram?.WebApp;
      if (tgApp) {
        return {
          width: tgApp.viewportStableWidth,
          height: tgApp.viewportStableHeight
        };
      }
      return {
        width: window.innerWidth,
        height: window.innerHeight
      };
    };

    // Function to create and configure the game
    const createGame = () => {
      const { width, height } = getViewportSize();

      const config = {
        type: Phaser.AUTO,
        width: width,
        height: height,
        parent: gameRef.current,
        scale: {
          mode: Phaser.Scale.RESIZE,
          autoCenter: Phaser.Scale.CENTER_BOTH
        },
        scene: {
          preload: preload,
          create: create,
          update: update
        }
      };

      return new Phaser.Game(config);
    };

    let text;
    let socket;

    function preload() {
      // Preload assets if needed
    }

    function create() {
      const { width, height } = this.scale;
      text = this.add.text(width / 2, height / 2, 'Connecting...', { fontSize: '24px', fill: '#fff' });
      text.setOrigin(0.5);

      // Open WebSocket connection
      socket = new WebSocket('wss://123.com');

      socket.onopen = () => {
        text.setText('Connected!');
      };

      socket.onmessage = (event) => {
        text.setText(`Received: ${event.data}`);
        // Add your game update logic here based on the received message
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
        text.setText('Connection error');
      };

      socket.onclose = () => {
        text.setText('Connection closed');
      };
    }

    function update() {
      // Add any continuous update logic here
    }

    // Create the game instance
    const game = createGame();
    setGameInstance(game);

    // Cleanup function
    return () => {
      if (game) {
        game.destroy(true);
      }
      if (socket) {
        socket.close();
      }
    };
  }, []);

  return (
    <div className="h-full w-full">
      <div ref={gameRef} className="w-full h-full"></div>
      
      <div className="absolute bottom-0 left-0 right-0 p-2 flex justify-around">
        <Link to="/dashboard">Dashboard</Link>
        <Link to="/team">Team</Link>
        <Link to="/bank">Bank</Link>
        <Link to="/user">User</Link>
      </div>
    </div>
  );
};

export default Gameplay;